.container {
  width: 100%;
  @media screen and (min-width: 1100px) {
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }

  @media screen and (min-width: 1250px) {
    max-width: 1250px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1390px;
  }
}

.topbar-container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  color: white;
  text-decoration: none;
  display: block;

  svg {
    margin-right: 10px;
  }

  .container {
    display: flex;
  }

  img.topbar-image {
    width: 100%;
  }
}

/************************************************************** HEADER */
.header {
  width: 100%;
  padding: 5px 16px 5px 16px;

  background-color: #f2f4f8;
  box-sizing: border-box;
  @media screen and (max-width: 1100px) {
    position: fixed;
    z-index: 100;
    padding: 5px 10px 5px 10px;
  }

  @media screen and (min-width: 1100px) {
    padding: 0;
    position: fixed;
    background-color: white;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 1105px;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 1249px) {
    width: 1255px;
  }
  @media screen and (min-width: 1399px) {
    width: 1395px;
  }

  .header-top {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 1100px) {
      padding: 40px 0px;
      display: flex;
      justify-content: space-between;
      margin-left: 0;
      width: 1100px;
    }
    @media screen and (min-width: 1249px) {
      width: 1250px;
    }
    @media screen and (min-width: 1399px) {
      width: 1390px;
    }
  }

  .logo {
    img {
      max-width: 150px;
    }

    @media screen and (max-width: 1100px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      img {
        max-width: 110px;
      }
    }

    cursor: pointer;
  }

  .header-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      margin-left: 16px;
    }
  }

  .desktopUserBox {
    padding: 5px 8px;
    border-radius: 5px;
    background: linear-gradient(270deg, #ff416c 0%, #ff4b2b 100%);
    margin-right: 0;
    margin-left: 10px;

    * {
      margin: 4px;
    }

    p {
      color: white;
    }
  }
}

.brands-slider {
  @media screen and (max-width: 1100px) {
    width: 95%;
  }

  .slick-track {
    display: flex !important;
    width: 100%;
    justify-content: space-between !important;

    div {
      width: fit-content !important;
    }
  }

  .slick-track:before,
  .slick-track:after {
    display: none;
  }
}
