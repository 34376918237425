@font-face {
  font-family: 'Firago-Regular';
  font-display: fallback;
  src: url('/fonts/FiraGO-Regular-min.woff') format('woff');
}

@font-face {
  font-family: 'Firago-Medium';
  font-display: fallback;
  src: url('/fonts/FiraGO-Medium-min.woff') format('woff');
}

// @font-face {
//   font-family: 'Firago-Bold';
//   font-display: fallback;
//   src: url('/fonts/FiraGO-Bold.woff') format('woff');
// }

@font-face {
  font-family: 'Roboto-Medium';
  font-display: fallback;
  src: url('/fonts/FiraGO-Medium-min.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-display: fallback;
  src: url('/fonts/Roboto-Bold-min.woff');
}

// @font-face {
//   font-family: 'Firago-Bold';
//   font-display: fallback;
//   src: url('/fonts/Firago/FiraGO-Bold.ttf');
// }
// @font-face {
//   font-family: 'Firago-Light';
//   font-display: fallback;
//   src: url('/fonts/Firago/FiraGO-Regular.ttf');
// }

// @font-face {
//   font-family: 'Firago-Bold';
//   font-display: fallback;
//   src: url('/fonts/Firago/FiraGO-Bold.ttf');
// }

// @font-face {
//   font-family: 'Roboto-Light';
//   font-display: fallback;
//   src: url('/fonts/Roboto/Roboto-Light.ttf');
// }

// @font-face {
//   font-family: 'Roboto-Regular';
//   font-display: fallback;
//   src: url('/fonts/Roboto/Roboto-Regular.ttf');
// }
