.review-modal {
  .choose-rating {
    border-bottom: 1px solid #dfe3e9;

    .stars {
      button {
        height: 16px;
        &:not(:first-child) {
          margin-left: 8px;
        }
        &.active {
          svg {
            path {
              fill: var(--color-yellow);
              stroke: transparent;
            }
          }
        }
      }
    }
  }
  .upload-image-button {
    background: var(--color-semiwhite);
    padding: 16px 27px;
    border-radius: 24px;
    color: #1a2738;
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
.cart-buy-buttons {
  & > * {
    margin-top: 10px;
  }
}
form {
  .form-section {
    h3.form-divider {
      border-bottom: 1px solid #dadada;
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 176px;
        height: 1px;
        background-color: var(--color-yellow);
      }
    }
  }
}
@media (min-width: 1024px) {
  .acc-mt{
    margin-top: 16px;
  }
}
.account-page {
  .account-page-header {
    margin-left: 270px;
  }
  .account-page-switcher {
    min-width: 270px;
    .single-switcher {
      color: var(--color-grey);
      border-top: 1px solid #eceeef;

      &.active {
        color: var(--color-darkgrey);
        font-family: var(--font-bold);
      }
    }
  }
}

.footer {
  .footer-payment,
  .footer-social {
    & > * {
      margin-left: 10px;
    }

    img {
      max-height: 25px;
      filter: grayscale(1);
    }
  }
}

[data-color='semiblack'] {
  color: var(--color-semiblack);
}

[data-color='darkgrey'] {
  color: var(--color-darkgrey);
}
.order-unique-url {
  a {
    color: var(--color-linkblue);
  }
}

.sidebar-filter {
  transform: scaleY(0);
  transform-origin: top;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  position: absolute;

  &.open {
    transform: scaleY(1);
    position: relative;
  }
}

.category-back-title {
  font-family: Firago-Bold;
  color: black;
  font-size: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

:root {
  --color-white: #ffffff;
  --color-orange: #ff6700;
  --color-semiblack: #172338;
  --color-semiwhite: #dfe3e9;
  --color-grey: #868686;
  --color-darkgrey: #172338;
  --color-linkblue: #1e90ff;
  --color-orange1: #ffb500;
  --color-orange2: #ffad00;
  --color-orangeLight: 'rgba(255,167,1,0.1)';
  --color-blue1: #1e90ff;
  --color-blue2: #02a8ff;
  --color-blue3: #15a6ff;
  --color-blue4: #00b3ff;
  --color-blue5: #268dff;
  --color-lightBlue: 'rgba(30,144,255,0.11)';
  --color-lightBlue1: 'rgba(21,166,255,0.1)';
  --color-lightBlue2: #f1f9fa;
  --color-black: #000000;
  --color-black1: #3c4854;
  --color-black2: #1a2738;
  --color-black3: #444c58;
  --color-black4: #5a6679;
  --color-black5: #172338;
  --color-grey: #919191;
  --color-grey1: #acb4b5;
  --color-grey2: #5c6772;
  --color-greyE5: #e5e5e5;
  --color-greyE4: #e4e4e4;
  --color-greyED: #ededed;
  --color-grey4A: #4a4a4a;
  --color-lightGrey: #e0e0e0;
  --color-lightGrey1: #eaeaea;
  --color-lightGrey2: #f2f4f8;
  --color-lightGrey3: #f4f6fa;
  --color-lightGrey4: #dfe4ea;
  --color-mediumGrey: #868686;
  --color-yellow: #ffce00;
  --breakpoint-xs: '0px';
  --breakpoint-sm: '576px';
  --breakpoint-md: '768px';
  --breakpoint-lg: '992px';
  --breakpoint-xl: '1200px';
  --font-size-xs: '12px';
  --font-size-sm: '14px';
  --font-size-md: '18px';
  --font-size-lg: '26px';
  --font-size-xl: '36px';
  --font-light: Firago-Light;
  --font-medium: Firago-Medium;
  --font-regular: Firago-Regular;
  --font-bold: Firago-Bold;
}

svg,
img {
  display: unset;
  vertical-align: unset;
} /* Override SVG */

*:focus {
  outline: none !important;
}
