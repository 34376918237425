.aff-container {
  // height: 2rem;
  width: 100%;

  background-color: #F2F4F5;
  display: flex;
  align-items: center;

  padding: 8px 20px;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  & input {
    height: 30px;
  }

  & button {
    height: 30px;
    padding: 0px 10px;
  }

  @media screen and (max-width: 1100px) {
    padding: 8px 0px;
  }
}